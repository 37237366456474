/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//font
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');


.tabs-inner{
  margin-top: 55px;
}

*{
  font-family: 'Red Hat Display', sans-serif;
}

// swiper imports
@import '~swiper/scss';
@import '~swiper/scss/pagination';
@import '~swiper/scss/navigation';
// @import '~@ionic/angular/css/ionic-swiper';

// ion-content{
//   --background: linear-gradient(45deg, rgba(255, 65, 172, 1) 0%, rgba(255, 122, 197, 1) 10.5%, rgba(255, 160, 214, 1) 40.33%, rgba(255, 227, 243, 1) 100%);
// }
